import { FormHelperText, makeStyles, Theme } from '@material-ui/core';
import cn from 'classnames';
import { connect, FormikProps } from 'formik';
import get from 'lodash/get';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
// TODO design-system: refactor !important styles after migration to new typography APP-1443

const useStyles = makeStyles((theme: Theme) => ({
  textArea: {
    width: '100%',
    minHeight: theme.spacing(16),
    borderRadius: 2,
    border: `1px solid`,
    padding: '14px 11px',
    outline: 0,
    resize: 'none',
    overflow: 'auto',
    transition: '.2s all',
  },
  error: {
    marginTop: theme.spacing(1),
  },
}));

type Props = {
  name: string;
  placeholder: string;
};
type OuterProps = {
  formik: FormikProps<any>;
};
const TextArea: React.FunctionComponent<Props & OuterProps> = ({
  formik,
  name,
  placeholder,
}) => {
  const classes = useStyles();
  const i18n = useIntl();

  const [onFocus, setOnFocus] = useState(false);

  const id = name || '';
  const error = get(formik, `errors.${id}`, false);
  const touched = get(formik, `touched.${id}`, false);
  const hasError = touched && error;
  const value = get(formik, `values.${id}`, '');
  const handleChange = get(formik, 'handleChange', undefined);
  const handleBlur = () => {
    setOnFocus(false);

    return get(formik, 'handleBlur', undefined);
  };

  return (
    <>
      <textarea
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={onFocus ? '' : placeholder}
        className={cn(
          classes.textArea,
          'body-16-regular !border-pearl placeholder:!text-black hover:!border-black focus:!border-black',
          {
            [cn(classes.error, '!border-red')]: hasError,
          }
        )}
        onFocus={() => setOnFocus(true)}
      />
      {hasError && (
        <FormHelperText error className={cn(classes.error, '!body-12-regular')}>
          {i18n.formatMessage({ id: error })}
        </FormHelperText>
      )}
    </>
  );
};

export default connect<Props, any>(TextArea);
