import { TrackingEventName, useTrackingStore } from '@finn/ua-tracking';
import { cn } from '@finn/ui-utils';
import {
  CheckboxProps,
  FormControlLabel,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { connect, FormikProps } from 'formik';
import get from 'lodash/get';
import React from 'react';

import Icon from '~/components/Icons/Checkbox';

import Checkbox from './';

// interface can not extend a union-type
type Props = CheckboxProps & {
  label?: string;
  alignment?: string;
  useLargeFont?: boolean;
};

interface IOuterProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: FormikProps<any>;
}

type StylesProps = {
  alignment?: string;
  hasError: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginRight: 0,
    display: 'flex',
    alignItems: 'flex-start',
  },
  label: {
    // we add margin to align first line of text with center of checkbox
    // so we do checkboxHeight - lineHeight ) / 2 (which is 2px)
    marginTop: (props: StylesProps) =>
      props.alignment === 'start' ? theme.spacing(0.5) : theme.spacing(0.5), // To align with the checkbox in sm screens
  },
  labelBig: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(1),
    },
  },
}));

const FormikInput: React.FunctionComponent<Props & IOuterProps> = ({
  formik,
  label,
  alignment,
  onChange,
  value,
  useLargeFont,
  ...rest
}) => {
  const id = rest.id || rest.name || '';
  const error = get(formik, `errors.${id}`, false);
  const touched = get(formik, `touched.${id}`, false);
  const hasError = touched && error;
  const checked = !!get(formik, `values.${id}`, false);
  const track = useTrackingStore((state) => state.track);

  const classes = useStyles({ alignment, hasError });

  const handleChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    isChecked: boolean
  ) => {
    track(TrackingEventName.CHECKBOX_CLICKED, {
      fieldName: id,
      additionalProps: { action: isChecked ? 'checked' : 'unchecked' },
    });
    formik.setFieldValue(id, isChecked);
    formik.setFieldError(id, '');
  };

  const isChecked = (value ? value : checked) as boolean;

  return (
    <FormControlLabel
      classes={{
        label: cn(
          'body-14-light before:inline after:inline',
          useLargeFont ? classes.labelBig : classes.label,
          {
            '!text-red': hasError,
            '!text-black': !hasError,
          }
        ),
        root: classes.root,
      }}
      control={
        <Checkbox
          icon={<Icon checked={false} />}
          checkedIcon={<Icon checked={true} />}
          checked={isChecked}
          error={hasError}
          onChange={onChange ? onChange : handleChange}
          name={id}
          onBlur={(e) => {
            formik.setFieldTouched(id);
            formik.handleBlur(e);
          }}
        />
      }
      label={label}
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect<Props, any>(FormikInput);
