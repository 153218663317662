import { FaqItem } from '@finn/auto-ui/types/faq';
import { traceabilityHeadersBrowser } from '@finn/ui-utils';
import axios from 'axios';

type FetcherProps = {
  message?: string;

  locale?: string;
};

export const faqFetcher = async ({
  message,
  locale,
}: FetcherProps): Promise<FaqItem[]> => {
  try {
    const response = await axios.post<FaqItem[]>(
      '/api/getFaq',
      { formMessage: message, languageGroup: locale },
      { headers: traceabilityHeadersBrowser() }
    );
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch {
    return [];
  }
};
