import { TrackingEventName, useTrackingStore } from '@finn/ua-tracking';
import { useCurrentLocale } from '@finn/ui-utils';
import { TextFieldProps } from '@material-ui/core';
import { connect, FormikProps } from 'formik';
import get from 'lodash/get';
import React, { useCallback } from 'react';
import { PhoneInput } from 'react-international-phone-field';
import { useIntl } from 'react-intl';

import { usePhoneInputStyle } from '~/components/FormElements/usePhoneInputStyle';
// TODO design-system: refactor !important styles after migration to new typography APP-1443

// interface can not extend a union-type
type Props = TextFieldProps & {
  displayCountryPrefix?: boolean;
  // external value and error for non-formik usage
  setValueExternal?: (value: string) => void;
  externalError?: string | boolean | null;
  onPhoneChange?: (event: { target: { name: string } }) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
};

interface IOuterProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: FormikProps<any>;
}

const FormikPhoneInput: React.FunctionComponent<Props & IOuterProps> = ({
  formik,
  onPhoneChange,
  onBlur,
  placeholder,
  label,
  setValueExternal,
  externalError,
  ...rest
}) => {
  const classes = usePhoneInputStyle();
  const track = useTrackingStore((state) => state.track);
  const i18n = useIntl();
  const id = rest.id || rest.name || '';
  const error = get(formik, `errors.${id}`, false);
  const touched = get(formik, `touched.${id}`, false);
  const hasError = touched && error;
  const value = get(formik, `values.${id}`, '');
  const { region } = useCurrentLocale();

  const handleChange = useCallback(
    (newValue, meta) => {
      const hasUserInput = newValue !== `+${meta?.country?.dialCode}`;
      if (hasUserInput) {
        track(TrackingEventName.FORM_FIELD_ENTERED, {
          name: id,
          once: true,
        });
      }
      formik?.setFieldValue?.(id, newValue);
      setValueExternal?.(newValue);
      onPhoneChange?.({ target: { name: id } });
    },
    [formik, id, onPhoneChange, setValueExternal, track]
  );

  const errMessage = error ? i18n.formatMessage({ id: error }) : '';

  return (
    <div className={classes.root}>
      <PhoneInput
        defaultCountry={(region || '').toLowerCase()}
        value={value || ''}
        onChange={handleChange}
        placeholder={placeholder}
        inputProps={{ autoComplete: 'tel', name: id, onBlur }}
        preferSelectedCountry
      />
      <span className={classes.label}>{label}</span>
      {(hasError || externalError) && (
        <p className="body-12-regular text-red mt-1">
          {externalError ? externalError : errMessage}
        </p>
      )}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect<Props, any>(FormikPhoneInput);
