import { makeStyles, Theme } from '@material-ui/core';

export const useFaqRecommenderStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(2, 0, 0),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  suggestions: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    '& a:hover': {
      textDecoration: 'underline',
    },
  },
  faqLink: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    '& svg': {
      flexBasis: '16px',
      flexShrink: 0,
    },
  },
}));
