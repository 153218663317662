import { FaqItem as FaqItemType } from '@finn/auto-ui/types/faq';
import { ArrowForward } from '@finn/design-system/icons/arrow-forward';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import React, { useCallback } from 'react';

import { useFaqRecommenderStyles } from './useFaqRecommenderStyles';

type Props = {
  item: FaqItemType;
  index: number;
};

export const FaqItem: React.FC<Props> = ({ item, index }) => {
  const classes = useFaqRecommenderStyles();
  const trackClick = useCallback(() => {
    interactionTrackingEvent(TrackingEventName.SUGGESTED_FAQ_CLICKED, {
      'FAQ Position': index + 1,
      link: item.link,
      title: item.title,
    });
  }, [index, item.link, item.title]);

  return (
    <div>
      <a
        href={item.link}
        target="_blank"
        rel="noreferrer"
        className={classes.faqLink}
        onMouseDown={trackClick}
      >
        <ArrowForward width={16} height={16} className="smallIcon" />
        <p className="body-14-light">{item.title}</p>
      </a>
    </div>
  );
};
